import { useEffect } from "react";

const ExitUrl = () => {
  const currentPath = location.pathname;

  if(currentPath.includes('L564Sdu')) {
    return adcnUrl;
  }

  if(currentPath.includes('DoZpPtB')) {
    return youtubeUrl;
  }

  return defaultExitUrl;
}

export const ExitPage = () => {
  useEffect(() => {
    console.log('location.pathname', location.pathname)
    console.log(ExitUrl());
    window.location.href = "https://www.nahls.co.jp";
  }, []);
  return null;
};
